import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO ({ description, lang, meta, keywords, title, image, path }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        
        // TODO: Verificar uma outra forma de receber ou enviar os dados sem o conteúdo HTML
        const metaDescription = description ? description.replace(/<[^>]*>/g, '') : data.site.siteMetadata.description 
        const url = data.site.siteMetadata.siteUrl
        const urlPath = `${url}${path || '/'}`
        const ogImage = `${url}${image ? image.src : "/assets/img/cover.png"}`
        const ogWith = image ? image.width : "500"
        const ogHeight = image ? image.height : "500"

        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title}
            titleTemplate={`%s`}
            meta={[
              {
                property: `og:url`,
                content: urlPath
              },
              {
                name: `description`,
                content: metaDescription
              },
              {
                name: "keywords",
                content: data.site.siteMetadata.keywords
              },
              {
                property: `og:title`,
                content: title
              },
              {
                property: `og:image`,
                content: ogImage
              },
              {
                property: `og:image:width`,
                content: ogWith
              },
              {
                property: `og:image:height`,
                content: ogHeight
              },
              {
                property: `og:description`,
                content: metaDescription
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                name: `twitter:card`,
                content: `summary`
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author
              },
              {
                name: `twitter:title`,
                content: title
              },
              {
                name: `twitter:description`,
                content: metaDescription
              }
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `)
                    }
                  : []
              )
              .concat(meta)}
          >
            <script 
              type='text/javascript' 
              async 
              src='https://d335luupugsy2.cloudfront.net/js/loader-scripts/dc1bcdc7-69e9-4b21-8702-b84a3591cda4-loader.js' 
            />
            <script 
              src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" 
              type="text/javascript" 
              charset="UTF-8" 
              data-domain-script="86b9194b-e6bc-4c0b-b093-3a9edf5c1da0" 
            />
            <script type="text/javascript">
              function OptanonWrapper() { }
            </script>
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  path: PropTypes.string
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        keywords
      }
    }
  }
`
