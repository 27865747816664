import { css } from '@emotion/core'
import reset from 'emotion-reset'
import ArrowLeft from '../../images/keyboard_arrow_left-black-18dp.svg'
import ArrowRigth from '../../images/keyboard_arrow_right-black-18dp.svg'

import ds from '../../theme'

export const globalStyles = css`
  ${reset}
  #___gatsby {
    height: 100%;
    & > div {
      height: 100%;
    }
  }
  html, body {
    height: 100%;
    font-family: 'Montserrat',sans-serif !important;
  }
  body {
    font-family: ${ds.get('type.fontFamily.sans')};
    line-height: ${ds.get('type.lineHeight.base')};
    padding-right: 0!important; /* Fix for material-ui Drawer behavior */
    overflow: auto!important;
  }
  a {
    text-decoration: none;
  }
  input,
  textarea {
    font-family: inherit;
    box-sizing: border-box;
  }
  button {
    border: none;
  }
  .MuiTypography-body1 {
    font-size: 14px;
    font-family: 'Montserrat',sans-serif !important;
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: normal;
    color: #737474;
  }
  .MuiTypography-body1:hover {
    color: rgba(0,0,0,0.87);
  }
  .MuiListItem-button:hover {
    text-decoration: none;
    background-color: #fff;
  }
  .PrivateNotchedOutline-root {
    border-radius: 22.5px;
    height: 56px;
    border: solid 1px rgba(0, 0, 0, 0.32);
  }
  .MuiFormControl-root {
    margin-bottom: 10px;
  }
  .MuiFormLabel-root {
    padding: 0;
    font-family: 'Montserrat',sans-serif !important;
    font-weight: 600 !important;
    line-height: 1.8;
    letter-spacing: 0.15;
  }
  .MuiInputBase-root {
    font-family: 'Montserrat',sans-serif !important;
    min-height: 56px;
    width: 328px;
  }
  .PrivateNotchedOutline-root-101 {
    border-radius: 50px;
  }
  .MuiInputBase-input {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
    font-size: 14px;
  }
  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 0px 0px 14px rgb(255, 245, 239);
  }
  .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 14px rgb(255, 245, 239);
  }
  .cookieConsent {
    font-family: 'Montserrat',sans-serif !important;
    align-items: center !important;
    button {
      font-family: 'Montserrat',sans-serif !important;
      border-radius: 22.5px !important;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14) !important;
      background-color: #fafafa !important;
      font-size: 13.7px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.16;
      letter-spacing: 1.25px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6) !important;
      width: 130px;
      height: 45px;
      /* margin-left: auto !important; */
      outline: none;
      margin-left: 0px !important;
    }
  }
  @media (min-width: 1033px) {
    .cookieConsent {
      div {
        margin-left: 24px !important;
      }
      button {
        margin-right: 250px !important;
      }
      button:nth-child(2) {
        margin-right: 0px !important;
        margin-left: auto;
      }
    }
  }
  @media (max-width: 1920px) {
    .cookieConsent {
      button:nth-child(2) {
        background-color: transparent !important;
        box-shadow: none !important;
        color: #fff !important;
        text-decoration: underline;
        margin-left: auto;
      }
    }
  }
  @media (min-width: 260px) and (max-width: 1000px) {
    .cookieConsent {
      div {
        margin-bottom: 0px !important;
      }
      button {
        margin-right: 100px !important;
      }
      button:nth-child(2) {
        margin-right: 0px !important;
      }
    }
  }
  #drift-widget-container {
    z-index: 9999 !important;
  }
  .MuiDrawer-root, .MuiDrawer-modal{
    z-index: 99 !important;
  }
  .slick-dots li {
    margin: 0px;
  }
  .slick-dots li button:before {
    font-size: 12px;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #878694 !important;
  }
  .slick-prev, .slick-next {
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  .slick-prev:before {
    background: url(${ArrowLeft}) no-repeat; 
    color: transparent;
    background-position: center;
  }
  .slick-next:before {
    background: url(${ArrowRigth}) no-repeat; 
    color: transparent;
    background-position: center;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #e64b00 !important;
  }
  .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }
  .MuiRadio-colorSecondary.Mui-checked:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }
  .MuiFormControlLabel-root {
    margin-right: 0px !important;
  }
  /* accordion */
  .rc-collapse {
    /* padding: 24px; */
    background-color: transparent;
    /* border-radius: 16px; */
    transition: height 0.2s ease 0s;
    cursor: default;
    border: none;
  }
  .rc-collapse:not(:last-child) {
    margin-bottom: 16px;
  }
  .rc-collapse-item {
    padding: 24px;
    background-color: #fff;
    border-radius: 16px;
    /* margin-bottom: 16px; */
  }
  .rc-collapse > .rc-collapse-item {
    border-top: none;
  }
  .rc-collapse > .rc-collapse-item > .rc-collapse-header {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    cursor: pointer;
    font-size: 13.8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    outline: none;
    height: 42px;
  }
  .rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
    margin-right: 16px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid rgba(0, 0, 0, 0.87);
  }
  .rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow {
    margin-right: 16px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0.87);
  }
  .border-line {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 16px;
  }
  .rc-collapse-content {
    padding: 0;
  }
  /* Tab */
  .MuiBox-root {
    padding: 0px !important;
  }
  .PrivateTabIndicator-root-81 {
    background-color: #FF5800;
  }
  /* SLIDER */
  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: left;
    background-size: cover;
    height: 300px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 12px;
  }
  .react-slideshow-container .default-nav {
    max-width: 40px;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  }
  .react-slideshow-container .default-nav > svg {
    width: 10px;
  }
  .react-slideshow-container .default-nav:first-of-type {
    margin-left: 24px;
  }
  .react-slideshow-container .default-nav:last-of-type {
    margin-right: 24px;
  }
  .react-slideshow-container+div.indicators .each-slideshow-indicator:before {
    background: #878694;
    width: 8px;
    height: 8px;
  }
  @media (min-width: 260px) and (max-width: 1000px) {
    .react-slideshow-container .default-nav {
      display: none;
    }
  }
  /* TABS */
  .tab-link {
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    font-family: 'Montserrat',sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 61px;
    padding-right: 61px;
    color: #878694;
    cursor: pointer;
  }
  .tab-link-active {
    color: rgba(0, 0, 0, 0.87);
    outline: none;
  }
  .tab-content {
    display: flex;
  }
  @media (min-width: 260px) and (max-width: 1000px) {
    .tab-content {
      flex-direction: column;
    }
  }
  //TABS
  .App {
    color: white;
    /*font-family: sans-serif;
     background: #1c1d1f;
    height: 100vh;
    box-sizing: border-box; */
    :not(:last-child) {
      border-bottom: 2px solid #9013fe !important;
    }
  }

  .react-tabs {
    display: flex;
    /* width: 470px; */
    height: 612px;
    /* border: 1px solid #3c3e43;
    color: white;
    background: #1c1d1f; */
  }

  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 250px;
    margin: 0;
    padding: 0;
    color: white;
    background: #7D10DD;
    li {
      :not(:last-child) {
        border-bottom: 2px solid #9013fe !important;
      }
    }
  }

  .react-tabs__tab {
    height: 100px;
    list-style: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.25;
    letter-spacing: normal;
    color: #f9fafa;
    justify-content: center;
    align-items: center;
    display: flex;
    outline: none;
    :not(:last-child) {
      border-bottom: 2px solid #9013fe !important;
    }
  }

  .react-tabs__tab--selected {
    background: #7005CE;
    border-color: #7005CE;
    color: white;
    width: 260px;
    border-bottom: 2px solid #9013fe;
  }

  .react-tabs__tab-panel {
    display: none;
    width: 300px;
  }

  .react-tabs__tab-panel--selected {
    align-items: center;
    display: flex;
    margin-left: 78px;
    border-bottom: 2px solid #9013fe;
  }

  .panel-content {
    text-align: center;
    h2 {
      width: 220px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
  }

`
