import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import { Location } from "@reach/router"

// import Header from './Header'
// import HeaderBlog from './HeaderBlog'
import { globalStyles } from './styles/global.styles'
import ds from '../theme'

// import Footer from '../components/Footer'
// import SubNav from './SubNav'

const theme = {
  space: ds.get('spacing.gutters'),
  breakpoints: Object.keys(
      ds.get('breakpoints')
    ).map(
      key => ds.get('breakpoints')[key]
    ),
  ds
}

const Layout = ({
  children, location
}) => {
  const url = location.pathname
  return(
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            <ThemeProvider theme={theme}>
              <Global styles={globalStyles} />
              { url.includes('/blog')
              ?<div>
                {/* <HeaderBlog 
                  siteTitle={data.site.siteMetadata.title}
                /> */}
                {/* <div 
                  style={{
                    paddingTop: 60
                  }}
                  >
                  <main>{children}</main>
                
                  <Footer />
                </div> */}
              </div>
              :<div>
                {/* <Header 
                  siteTitle={data.site.siteMetadata.title}
                /> */}
                {/* <SubNav /> */}
                <div 
                  // style={{
                  //   paddingTop: 148
                  // }}
                  style={{
                    paddingBottom: 40
                  }}
                  >
                  <main>{children}</main>

                  {/* <Footer />  */}
                </div>
              </div>}
            </ThemeProvider>
          </>
      )}}
    />
)}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location:PropTypes.object.isRequired
}

export default props => {
  // console.log('props',props)
  return(
    <Location>
      {locationProps => {
        // console.log('locationprops', locationProps)
        return(<Layout {...locationProps} {...props} />)
      }
      }
    </Location>
)};
